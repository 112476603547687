.Footer {
  background-color: #6a6a6a;
  padding: 20px 80px;
  display: flex;
  justify-content: space-around;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.FooterItem {
  text-align: center;
  width: 250px;
}

.FooterTitle {
  font-size: 28px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .Footer {
    padding: 20px 20px;
  }
}
