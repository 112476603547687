.FooterMobile {
  background-color: #6a6a6a;
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.FooterMobileItem {
  width: 180px;
  text-align: center;
}

.FooterMobileTitle {
  font-size: 26px;
  font-weight: bold;
}
