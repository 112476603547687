.ReservedSpace {
  width: 100%;
  height: 110px;
}

.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  padding: 0 170px;
  border-bottom: 1px solid rgb(194, 194, 194);
  z-index: 3;

  transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
}

.NavBar .NavItem {
  width: 20%;
  text-align: center;
  transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  color: unset;
}
.NavBar .NavItem:hover {
  border-bottom: 3px solid rgb(95, 95, 95);
  text-decoration: none;
  color: unset;
}
.ActiveNav {
  border-bottom: 3px solid rgb(95, 95, 95);
}
.NavBar .NavBrand {
  position: relative;
  width: 20%;
  text-align: center;
  margin: auto;
  transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
}

.NavBar .NavBrand img {
  /* width: 100%; */
  max-width: 100%;
  max-height: 100%;
}

.PaddingTopBig {
  padding-top: 50px;
}

.PaddingTopSmall {
  padding-top: 10px;
}

.HeightBig {
  height: 110px;
}

.HeightSmall {
  height: 45px;
}

.MyLink {
  text-decoration: none;
  color: unset;
}
.MyLink:hover {
  text-decoration: none;
  color: unset;
}

@media only screen and (max-width: 1300px) {
  .NavBar {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .NavBar {
    padding: 0 0px;
  }
  .NavBar .NavItem {
    width: 17%;
  }
  .NavBar .NavBrand {
    width: 25%;
  }
}

@media only screen and (min-width: 1500px) {
  .NavBar .NavBrand {
    width: 17%;
  }
}
@media only screen and (min-width: 1700px) {
  .NavBar .NavBrand {
    width: 15%;
  }
}
