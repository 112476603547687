.ReadMoreBtn {
  text-align: right;
  margin-top: 10px;
}

.ReadMoreBtn a {
  /* text-align: right; */
  /* margin-top: 10px; */
  cursor: pointer;
  color: #6a6a6a;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.ReadMoreBtn a:hover {
  color: #6a6a6a;
  text-decoration: none;
}

.ProjectInfoText {
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .ReadMoreBtn {
    text-align: center;
  }
  .ProjectInfoText {
    text-align: center;
  }
}
