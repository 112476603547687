.WidgetItem {
  width: 150px;
  height: 150px;
  background-color: var(--mainColor);
  display: flex;
  align-items: center;
  margin: 0 30px;
  border-radius: 100%;
  text-align: center;
}
.WidgetItem > div {
  text-align: center;
  width: 100%;
  color: white;
}

.WidgetValue {
  font-size: 60px;
  margin: 0;
}

.WidgetUnit {
  font-size: 20px;
}

.DescText {
  font-size: 20px;
  /* margin-top: 10px; */
}

@media only screen and (max-width: 768px) {
  .WidgetItem {
    width: 110px;
    height: 110px;
    margin: 0 15px;
  }

  .WidgetValue {
    font-size: 50px;
    margin: 0;
  }
  .DescText {
    font-size: 16px;
  }
}

@media only screen and (max-width: 420px) {
  .WidgetItem {
    width: 80px;
    height: 80px;
    margin: 0 15px;
  }
  .WidgetValue {
    font-size: 30px;
    margin: 0;
  }
}
@media only screen and (max-width: 350px) {
  .WidgetItem {
    width: 60px;
    height: 60px;
    margin: 0 15px;
  }
  .WidgetValue {
    font-size: 20px;
    margin: 0;
  }
}
