.TextBanner {
  background-color: #f3f3f3;
  text-align: center;
  padding: 0 28%;
  overflow: hidden;
  width: 100%;
}

.EternoBtn {
  padding: 10px 15px;
  border: none;
  background-color: #706f6f;
  color: white;
  font-weight: bold;
  font-size: 18px;

  transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
}

.EternoBtn:hover {
  background-color: #787878;
  text-decoration: none;
  color: white;
}

.EternoBtn:focus {
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .TextBanner {
    padding: 0 18%;
  }
}

@media only screen and (max-width: 768px) {
  .TextBanner {
    padding: 0 5%;
  }
}

.EternoColor {
  color: var(--mainColor);
}
