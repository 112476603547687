.TitleText {
  font-size: 20px;
}
.ValueText {
  font-size: 40px;
}

@media only screen and (max-width: 768px) {
  .TitleText {
    font-size: 16px;
  }
  .ValueText {
    font-size: 30px;
  }
}
