.FloorblueprintContainer {
  padding: 25px 0;
}

.DataList {
  width: 50%;
  padding: 10px;
}
.BlueprintImg {
  padding: 10px;
  text-align: center;
}

.TitleText {
  font-size: 20px;
}
.ValueText {
  font-size: 40px;
}

.BlueprintImgSize {
  width: 70%;
}

@media only screen and (max-width: 1024px) {
  .FloorblueprintContainer {
    flex-direction: column;
    padding: 25px 0;
  }

  .DataList {
    width: 100%;
  }

  .BlueprintImg {
    width: 100%;
  }

  .BlueprintImgSize {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .TitleText {
    font-size: 16px;
  }
  .ValueText {
    font-size: 30px;
  }
}
