.Banner {
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {
  .Banner {
    height: 35vh;
  }
}
