.SubSecHeader {
  padding: 0 20px;
  color: #4a4a4a;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.Active {
  color: var(--mainColor);
  font-weight: bold;
  text-decoration: underline;
}
