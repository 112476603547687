.LocationDetailHeader {
  text-align: center;
}

.LocationTitle {
  font-size: 28px;
  font-weight: bold;
  color: var(--mainColor);
}

.LocationSubTitle {
  font-size: 20px;
}

.LocationWidgetContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LocationWidgetContainer .LocationWidgetItem {
  width: 150px;
  height: 150px;
  background-color: #f07000;
  display: flex;
  align-items: center;
  margin: 0 10px;
  border-radius: 100%;
}
