.FloorInformationContainer {
  display: flex;
}

.TextSection {
  width: 50%;
  margin: auto;
}
.TextSection > div {
  text-align: center;
}

.CoverSection {
  width: 50%;
}

.FloorTitle {
  font-weight: bold;
  font-size: 50px;
}

.FloorSubtitle {
  font-size: 22px;
  color: var(--mainColor);
  font-weight: bold;
}

.FloorDescription {
  font-size: 22px;
  max-width: 80%;
  margin: 15px auto 0 auto;
}

.FloorButton {
  border: none;
  background-color: #706f6f;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 8px 10px;
}

.FloorButton:hover {
  background-color: #787878;
  text-decoration: none;
  color: white;
}

.FloorButton:focus {
  outline: none;
}

.FlexCol {
  flex-direction: column;
}

.MobilePicSize {
  width: 100%;
  /* padding: 0 20px; */
  /* margin-top: 20px; */
}

.MobileTextSectionSize {
  width: unset;
}
