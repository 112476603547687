.ImgDesc {
  color: #a4a4a4;
  font-size: 25px;
}

@media only screen and (max-width: 1024px) {
  .ImgDesc {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .ImgDesc {
    font-size: 15px;
  }
}
