.BlueprintSlide {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.SubSecHeader {
  padding: 0 20px;
  color: #4a4a4a;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

@media only screen and (max-width: 768px) {
  .BlueprintSlide {
    width: unset;
    /* text-align: center; */
  }
}
