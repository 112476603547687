.PhyContactContainer {
  margin: 20px 15%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.PhyContactContainer > div {
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.Icon img {
  width: 65px;
}
.Icon > div {
  margin-top: 5;
  color: #b36114;
  font-weight: bold;
}
.Info {
  margin-top: 20px;
  font-size: 20px;
  /* font-weight: bold; */
}
.MiddleInfo {
  border-left: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
  padding: 10px;
}
@media only screen and (max-width: 1024px) {
  .PhyContactContainer {
    margin: 20px 10%;
  }
}

@media only screen and (max-width: 768px) {
  .hiddenXS {
    display: none;
  }
  .PhyContactContainer {
    flex-direction: column;
    /* margin: 0 auto; */
  }
  .PhyContactContainer > div {
    width: unset;
    /* max-width: 500px; */
    /* min-width: 400px; */
    flex-direction: row;
    padding: 10px 0;
  }
  .Icon {
    margin-right: 20px;
  }
  .Icon img {
    width: 55px;
  }
  .Info {
    margin: auto 0;
    text-align: left;
  }

  .MiddleInfo {
    border-left: none;
    border-right: none;
    border-top: 1px solid rgb(235, 235, 235);
    border-bottom: 1px solid rgb(235, 235, 235);
  }
}
