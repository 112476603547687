.MyLink a {
  color: #6b6b6b;
  font-size: 20px;
}

.MyLink a:hover {
  color: #2c2c2c;
  text-decoration: none;
}

.MapSize {
  width: 90%;
  height: 560px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .MapSize {
    min-height: 320px;
    height: 70vw;
  }
}
