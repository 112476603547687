.ReservedSpace {
  height: 49px;
}

.NavBarXs {
  position: fixed;
  height: 49px;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid rgb(194, 194, 194);
  z-index: 3;

  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
}

.NavBarXs .NavBrandXs {
  height: 50px;
  padding: 12px;
}

.NavBarXs .NavBrandXs img {
  /* height: 85%; */
  height: 21px;
  cursor: pointer;
}

.NavBarXs .NavItemXs {
  padding: 3px 0;
  padding-left: 15px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.NavBarXs .NavItemXs:hover {
  background-color: rgb(224, 224, 224);
}
.ActiveNav {
  background-color: rgb(224, 224, 224);
}

.NavMenu {
  position: absolute;
  right: 15px;
  top: 7px;
  /* border: 1px solid rgb(189, 189, 189); */
  padding: 4px;
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.ActiveMenu {
  height: 169px;
}

.MyLink {
  text-decoration: none;
  color: unset;
}
.MyLink:hover {
  text-decoration: none;
  color: unset;
}
